
































import { Component, Prop, Vue } from 'vue-property-decorator';
import Card from '@/shared/resources/components/cards/Card.vue';
import Loader from '@/shared/resources/components/Loader.vue';
import ApiV1Private from '@/shared/lib/api/query-builders/ApiV1Private';
import ApiV1Settings from '@/core/api/settings/ApiV1Settings';
import IconMessage from '@/shared/resources/components/IconMessage.vue';
import AuthLayoutLogo from '@/app/layouts/auth/AuthLayoutLogo.vue';

@Component({
  components: {
    AuthLayoutLogo,
    IconMessage,
    Loader,
    Card,
  },
})
export default class AuthRegisterConfirm extends Vue {
  /**
   * Props
   */
  @Prop() private hash!: string;

  /**
   * Data
   */
  private expandLogo: boolean = true;
  private loading: boolean = false;
  private success: boolean = false;
  private errorMessage: string | null = null;

  /**
   * Display getters
   */
  private get displayLoader(): boolean {
    return this.loading;
  }

  private get displaySuccessInfo(): boolean {
    return this.success && !this.loading;
  }

  private get displayErrorInfo(): boolean {
    return !!this.errorMessage && !this.loading;
  }

  /**
   * Lifecycle hooks
   */
  private created() {
    this.confirmRegistration();
  }

  /**
   * Methods
   */
  private async confirmRegistration() {
    this.loading = true;

    try {
      await new ApiV1Private()
        .schema(ApiV1Settings.schema)
        .where('hash', this.hash)
        .get('/v2/contractors-auth/verify-email');

      this.success = true;
    } catch (ex) {
      if (ex.isNotFound()) {
        this.errorMessage = 'Adres email najprawdopodobniej został już potwierdzony.';
      } else {
        this.errorMessage = 'Wystąpił nieoczekiwany błąd. Prosimy o&nbsp;kontakt.';
      }
    }

    this.expandLogo = false;
    this.loading = false;
  }
}
